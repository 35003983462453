/* minimum sizes are composed into viewer component in case there is no parent with proper styles */
.minimumSizes {
  min-width: 100px;
  min-height: 100px;
}
.wrapper {
  position: relative;
  height: 100%;
  composes: minimumSizes;
}
.rounded {
  border-radius: 100%;
  overflow: hidden;
}
.controls {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
}
@media (max-width: 1450px) {
  .controls {
    display: none;
  }
}

.cropper {
  composes: minimumSizes;
  display: block;
  width: inherit;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
