.leftPane {
  margin-right: 0.5em;
}
.form {
  display: flex;
  padding: 1em;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color-paper);
  position: sticky;
  top: 0;
  z-index: var(--z-index-appbar);
}
