@use 'standard-org-props.module';
.ediexport {
  @extend .actions;
  background-color: var(--background-color-paper);

  margin-bottom: 3em;
  display: grid;
  gap: 1em;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'title    title'
    'format   type'
    'endpoint endpoint'
    'server   dir'
    'user     pass'
    'buttons  buttons';
}
.orderFormat {
  grid-area: format;
}
.type {
  grid-area: type;
}
.ftpServer {
  grid-area: server;
}
.ftpDir {
  grid-area: dir;
}
.user {
  grid-area: user;
}
.password {
  grid-area: pass;
}
.endpoint {
  grid-area: endpoint;
}
.buttons {
  grid-area: buttons;
}
.title {
  grid-area: title;
}
