.root {
  padding: 1em;
}
.formControl {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.formControlWithMargin {
  @extend .formControl;
  margin-bottom: 2em;
}
.input {
  flex: 1;
  margin-right: 1em;
}
