.form {
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected {
  padding: 1em;
  display: grid;
}

.list {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}
.prices {
  // padding: var(--default-padding);
  padding: 0;
}
.mappingDrawer {
  width: 45vw;
}
.actions {
  padding: 1em;
  button {
    margin-right: 1em;
  }
}
.listPanel {
  margin-right: 0.5em;
  margin-left: 0.5em;
}
.formPanel {
  margin-right: 0.5em;
}
