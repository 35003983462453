.root {
}
.actions {
  position: sticky;
  top: 5em;
  background-color: var(--background-color-paper);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  padding: 1em 0;
  z-index: 1;
}
.table {
  margin: 2em 0;
}
.tableButtons {
  display: flex;
  flex-direction: row;
}
